import React from 'react'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import theme from '../../utils/theme';

const BackButton = () => {
    const navigate = useNavigate()
    const handleOnClick = ()=>{
        navigate(-1)
    }
  return (
    <Box onClick={()=> handleOnClick()} sx={{ display: 'flex', alignItem: 'center', cursor: 'pointer' }}>
        <KeyboardBackspaceIcon sx={{ color: theme.palette.primary.main, mr: 2 }}/>
        <Typography sx={{ color: theme.palette.primary.main }}>Back</Typography>
    </Box>
  )
}

export default BackButton
