import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { splitDate } from "../../../../utils/splitDate";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import { getBurmeseMonthName } from "../../../../utils/getBurmeseMonthName";
import { getFullMonthName } from "../../../../utils/getFullMonthName";
import { convertYenToMmk } from "../../../../utils/convertYenToMmk";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    position: "relative",
    paddingBottom: '30px',
  },
  content: {
    fontFamily: "PdsFont",
    fontSize: "20px",
    lineHeight: "35px",
  },
  boldText: {
    fontWeight: 'bold',
  },
  tableCell: {
    fontFamily: 'PdsFont',
    fontSize: '20px',
    paddingTop: '15px',
    paddingLeft: '50px',
    minHeight: '50px',
  },
  divider: {
    paddingLeft: '50px',
    height: '1px',
    backgroundColor: '#000',
  },
  dottedUnderline: {
    borderBottom: "1px dotted #000",
    display: 'inline',
    fontFamily: 'PdsFont',
    fontSize: '20px'
  },
});

const MoeafCoverLetter = ({data}) => {
  const [ preData,setPreData ] = useState(null)
  const classes = useStyles();
  
  useEffect(()=>{
    if(data){
      setPreData(data)
      console.log(data);     
    }
  },[data])

  return (
    <div
      className={classes.screen}
      style={{
        position: "relative",
        paddingBottom: '20px'
      }}
    >
        {
          preData &&
          <Box>
          <Box
            sx={{
              p: 12,
            }}
          >
            <Typography
              className={classes.content}
              textAlign="center" mb={5}
            >
              မိမိအစီအစဉ်ဖြင့် ပြည်ပသို့ သွားရောက်လုပ်ကိုင်မည့် ကျွမ်းကျင်အလုပ်သမားများမှ <br/> ပြည်ပ
              အလုပ်အကိုင်လမ်းညွှန်သင်တန်း တက်ရောက်ခွင့်ပြုပါရန် 
            </Typography>
            <Typography
              className={classes.content}
            >
              သို့
            </Typography>
            <Box
              sx={{
                pl: 2,
              }}
            >
              <Typography
                className={classes.content}
              >
                ညွှန်ကြားရေးမှူးချုပ်
              </Typography>
              <Typography
                className={classes.content}
              >
                အလုပ်သမားညွှန်ကြားရေးဦးစီးဌာန
              </Typography>
              <Typography
                className={classes.content}
              >
                အလုပ်သမားဝန်ကြီးဌာန
              </Typography>
              <Typography
                className={classes.content}
              >
                ရုံးအမှတ်၊ ၅၁၊ အလုပ်သမားဝန်ကြီးဌာန၊ နေပြည်တော်
              </Typography>
            </Box>
            <Typography
              sx={{
                my: 5,
                textAlign: "end",
              }}
              className={classes.content}
            >
              ရက်စွဲ၊ {convertToMyanmarNumbers(splitDate(preData.date).year)}ခုနှစ်
              &nbsp;{getBurmeseMonthName(getFullMonthName(splitDate(preData.date).month))}လ
              &nbsp;{convertToMyanmarNumbers(splitDate(preData.date).day)}ရက်
            </Typography>
            <Typography
              className={classes.content}
            >
              အကြောင်းအရာ။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
              style={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "35px", fontWeight: 'bold' }}
              >ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းတက်ရောက်ခွင့်ပြုပါရန် တင်ပြခြင်း။ </span>
            </Typography>
            <br/>
            <Typography
              className={classes.content}
              sx={{
                textAlign: "justify",
              }}
            >
              ၁။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;အထက်အကြောင်းအရာပါကိစ္စနှင့်ပတ်သက်၍&nbsp;
              <span className={classes.dottedUnderline}>{preData.stateOrDivisionMm}&nbsp;</span>
              တိုင်းဒေသကြီး ၊
              <span className={classes.dottedUnderline}>{preData.townshipMm}&nbsp;</span>
              မြို့နယ် ၊<span className={classes.dottedUnderline}>{preData.wardOrVillageMm}</span>{" "}
              ရပ်ကွက်၊&nbsp;
              <span className={classes.dottedUnderline}>{preData.roadMm}</span>&nbsp;လမ်း၊ အမှတ်
              ( <span className={classes.dottedUnderline}>{preData.addressNoMm}</span> ) နေ
              {Number(preData.gender) === 1 ? "ကျွန်တော် " : "ကျွန်မ "}&nbsp;
              <span className={classes.dottedUnderline}>{preData.nameMm}</span>&nbsp;
              နိုင်ငံသားစိစစ်ရေးကတ်အမှတ်<br></br>
              <span className={classes.dottedUnderline}>{preData.nrcMm}</span> (
              သာမန် မြန်မာနိုင်ငံကူးလက်မှတ်အမှတ် &#123; Passport No &#125; &nbsp;
              <span className={classes.dottedUnderline}>{preData.passport}</span> ) သည်
              <span className={classes.dottedUnderline}> ဂျပန်</span> နိုင်ငံ၊
              <span className={classes.dottedUnderline}> {preData.company.region}</span>&nbsp;ဒေသရှိ&nbsp;
              <span className={classes.dottedUnderline}>{preData.company.nameEng}</span>
              &nbsp;ကုမ္ပဏီတွင်&nbsp;
              <span className={classes.dottedUnderline}>{preData.jobCategoryMm}</span>&nbsp;
              အလုပ်ဖြင့် ယန်း &nbsp;
              <span className={classes.dottedUnderline}>{convertToMyanmarNumbers(preData.basicSalary)}</span> လစာ ( မြန်မာငွေ{" "}
              <span className={classes.dottedUnderline}>
                {preData.basicSalary && convertToMyanmarNumbers(convertYenToMmk(Number(preData.basicSalary), preData.currencyExchangeRate.length > 0 ? Number(preData.currencyExchangeRate.find(rate=> rate.key === 'mmk_per_yen').value) : 0))}
              </span> သိန်းခန့်)
              ရရှိမည်ဖြစ်သော အလုပ်အကိုင်အား အလုပ်ရှင်မှ တိုက်ရိုက်ခေါ်ဆိုသော
              အလုပ်လျှောက်လွှာကို မိမိ၏အစီအစဉ်ဖြင့် လျှောက်ထားခြင်း၊
              အင်တာဗျူးအောင်မြင်ခြင်းများကြောင့် အလုပ်သမားအဖြစ် ခန့်အပ်ရန်
              ရွေးချယ်ခံရသဖြင့် ပြည်ပသို့ သွားရောက်အလုပ်လုပ်ကိုင်ရမည်ဖြစ်ပါသည်။
              <br/>
              ၂။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;သို့ဖြစ်ပါ၍ {Number(preData.gender) === 1 ? "ကျွန်တော် " : "ကျွန်မ "}&nbsp;
              <span className={classes.dottedUnderline}>{preData.nameMm}</span>
              &nbsp;အား ပြည်ပအလုပ်အကိုင် လမ်းညွှန်သင်တန်း တက်ရောက် ခွင့်ပြုပါရန်နှင့်  ရန်ကုန်မြို့ရှိ မြောက်ဒဂုံ ပြည်ပအလုပ်အကိုင် လမ်းညွှန်သင်တန်းကျောင်းသို့ တက်ရောက်ခွင့်ပြုပါရန် 
              တင်ပြအပ်ပါသည်။
              <br/>
              <br/>
            </Typography>
            <Typography
              sx={{
                textDecoration: "underline",
                mt: 2,
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "35px",
              }}
            >
              ပူးတွဲပါအထောက်အထားများ-
            </Typography>
            <Box
              sx={{
                pl: 2,
                width: "80%",
              }}
            >
              <Typography
                className={classes.content}
              >
                (က)&nbsp;&nbsp;နိုင်ငံကူးလက်မှတ်မိတ္တူ
              </Typography>
              <Typography
                className={classes.content}
              >
                (ခ)&nbsp;&nbsp;&nbsp;COE မိတ္တူ
              </Typography>
              <Typography
                className={classes.content}
              >
                (ဂ)&nbsp;&nbsp;&nbsp;Demand Letter မိတ္တူ
              </Typography>
              <Typography
                className={classes.content}
              >
                (ဃ)&nbsp;&nbsp;Employment Contract မိတ္တူ
              </Typography>
              <Typography
                className={classes.content}
              >
                (င)&nbsp;&nbsp;&nbsp;ဘွဲ့လက်မှတ် မိတ္တူ
              </Typography>
            </Box>
            <Box sx={{
                      display: 'flex',
                      justifyContent: 'end',
                      mt: 10
                  }}>
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px'}}>လက်မှတ်</td>
                            <td style={{ width: '300px', paddingLeft: "50px", minHeight: '50px' }}>
                              <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}></p>
                              <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px'}}>အမည်</td>
                            <td style={{ width: '300px', paddingLeft: "50px", minHeight: '50px' }}>
                              <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>{preData.nameMm}</p>
                              <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                            </td>
                          </tr><tr>
                            <td style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px'}}>ရက်စွဲ</td>
                            <td style={{ width: '300px', paddingLeft: "50px", minHeight: '50px' }}>
                              <p style={{ fontFamily: 'PdsFont', fontSize: '20px', paddingTop: '15px', textAlign: 'center' }}>
                                {convertToMyanmarNumbers(splitDate(preData.date).day)}.
                                {convertToMyanmarNumbers(splitDate(preData.date).month)}.
                                {convertToMyanmarNumbers(splitDate(preData.date).year)}
                              </p>
                              <div style={{ paddingLeft: '50px', height: '1px', backgroundColor: '#000' }}></div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                </Box>
          </Box>
        </Box>
        }
    </div>
  );
};

export default MoeafCoverLetter;
