import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import { splitDate } from "../../../../utils/splitDate";
import { getFullMonthName } from "../../../../utils/getFullMonthName";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
});

const DlEng = () => {
  const [dmData, setDmData] = useState(null);
  const pdfData = useSelector((state) => state.PrintData.data);
  const classes = useStyles();

  const demandList = dmData
    ? [
        {
          title: "Company name",
          data: dmData.company.nameEng,
        },
        {
          title: "Company address",
          data: dmData.company.addressEng,
        },
        {
          title: "Number of recruitment",
          data: `${dmData.numberOfWorkers.total} persons`,
        },
        {
          title: "Gender",
          data: `${
            dmData.numberOfWorkers.male > 0 &&
            dmData.numberOfWorkers.female === 0
              ? "Male"
              : dmData.numberOfWorkers.male === 0 &&
                dmData.numberOfWorkers.female > 0
              ? "Female"
              : `Male(${dmData.numberOfWorkers.male})/ Female(${dmData.numberOfWorkers.female})`
          }`,
        },
        {
          title: "Age",
          data: "from 19 to 25 years old",
        },
        {
          title: "Qualification",
          data: dmData.qualificationENG,
        },
        {
          title: "Health & Must be fit Physically",
          data: "Must pass the medical check in Myanmar",
        },
        {
          title: "Job category",
          data: dmData.jobCategoryENG,
        },
        {
          title: "Workplace",
          data: dmData.workPlace,
        },
        {
          title: "Working hours",
          data: dmData.workingHoursENG,
        },
        {
          title: "Basic Salary",
          data: `${Number(dmData.basicSalary).toLocaleString()}-yen`,
        //   data2: "(1,023Yen X 8hours X 20.25day)",
        },
        {
          title: "Salary Payment",
          data: Number(dmData.salaryPaymentMethod) === 1 ? "Bank Transfer": "By Cash",
        },
        {
          title: "Overtime",
          data: "Additional 25% of Basic Wage-Hour",
        },
        {
          title: "Working days",
          data: dmData.workingDayEng,
        },
        {
          title: "Holidays",
          data: dmData.holidayENG,
        },
        {
          title: "Accomodation",
          data: "Arranged by the company",
          data2: `Monthly payment of ${dmData.accommodation}-Yen for each Trainee`,
        },
        {
          title: "Transportation/Air Ticket",
          data: "Borne by employer",
        },
        {
          title: "Food",
          data: "Foods are arranged by Trainee",
        },
        {
          title: "Training period",
          data: "1 Month",
        },
        {
          title: "Training period Wage scale",
          data: `${dmData.trainingPeriodWageScale} Yen`,
        },
        {
          title: "Contract period",
          data: "3 Years",
        },
        {
          title: "Medical insurance",
          data: "JITCO apprentice comprehensive insurance",
        },
      ]
    : [];

  useEffect(() => {
    if (pdfData) {
      const { data } = pdfData;
      setDmData(data);
    }
  }, [pdfData]);

  return (
    <Box className={classes.screen} sx={{ px: 15, py: 10 }}>
        {
            dmData &&
            <>
                <Box
                    sx={{
                    width: "100%",
                    mb: 3,
                    }}
                >
                    <Typography sx={{ fontFamily: "PdsFont", fontSize: "20px" }}>
                    Cooperative Name: {dmData.organization.nameEng}
                    </Typography>
                    <Typography sx={{ fontFamily: "PdsFont", fontSize: "20px" }}>
                    Cooperative Address: No.86-1, {dmData.organization.addressEng}
                    </Typography>
                    <Typography sx={{ fontFamily: "PdsFont", fontSize: "20px" }}>
                    Tel: {dmData.organization.phone}
                    </Typography>
                    <Typography sx={{ fontFamily: "PdsFont", fontSize: "20px" }}>
                    Fax: {dmData.organization.fax}
                    </Typography>
                </Box>
                <Box
                    sx={{
                    width: "70%",
                    }}
                >
                    <Typography sx={{ fontFamily: "PdsFont", fontSize: "20px" }}>
                    Ms. Chit Su Wai
                    </Typography>
                    <Typography sx={{ fontFamily: "PdsFont", fontSize: "20px" }}>
                    Managing Director
                    </Typography>
                    <Typography sx={{ fontFamily: "PdsFont", fontSize: "20px" }}>
                    Clover Mandalay Co.,Ltd.
                    </Typography>
                    <Typography sx={{ fontFamily: "PdsFont", fontSize: "20px" }}>
                    No.24, Kant Kaw Street, Between 22ndx23rd, 88thx89th Street,<br></br> Aung Myay
                    Thar Zan Tsp, Mandalay, Myanmar.
                    </Typography>
                </Box>
                <Typography
                    sx={{
                    my: 3,
                    textAlign: "end",
                    fontFamily: "PdsFont",
                    fontSize: "20px",
                    }}
                >
                    Date {splitDate(dmData.demandLetterApplicationDate).day} {getFullMonthName(splitDate(dmData.demandLetterApplicationDate).month)} {splitDate(dmData.demandLetterApplicationDate).year}
                </Typography>
                <Typography
                    sx={{
                    fontSize: "30px",
                    textAlign: "center",
                    fontFamily: "PdsFont",
                    }}
                >
                    LETTER OF DEMAND FOR WORKER RECRUITMENT
                </Typography>
                <Typography
                    sx={{
                    my: 3,
                    fontFamily: "PdsFont",
                    fontSize: "20px",
                    }}
                >
                    Dear Ms. Chit Su Wai,
                </Typography>
                <Typography sx={{ fontFamily: "PdsFont", fontSize: "20px", textAlign: 'justify', textIndent: '50px' }}>
                    This is to confirm that we hereby appoint and authorize your agency to
                    recruit Myanmar workers for employment in our Company. You are also to
                    have the relevant authority's approval regarding the recruitment on
                    behalf.
                </Typography>
                <Typography
                    sx={{
                    my: 3,
                    fontFamily: "PdsFont",
                    fontSize: "20px",
                    }}
                >
                    Our recruitment and terms of employment are as follows:
                </Typography>
                {demandList.map((demandData, index) => (
                    <Grid
                    key={index}
                    container
                    spacing={2}
                    sx={{
                        pb: 3,
                        pageBreakAfter: index === 13 ? "always" : "",
                        pt: index === 14 ? 14 : 0,
                    }}
                    >
                    <Grid item xs={4}>
                        <Box
                        sx={{
                            display: "flex",
                        }}
                        >
                        <Typography
                            sx={{
                            mr: 2,
                            }}
                        >
                            {index + 1}.
                        </Typography>
                        <Typography sx={{ fontFamily: "PdsFont", fontSize: "20px" }}>
                            {demandData.title}
                        </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography sx={{ fontFamily: "PdsFont", fontSize: "20px" }}>
                        : {demandData.data}
                        </Typography>
                        <Typography sx={{ pl: 1, fontFamily: "PdsFont", fontSize: "20px" }}>
                        {demandData.data2}
                        </Typography>
                    </Grid>
                    </Grid>
                ))}
                <Box
                    sx={{
                    textAlign: "end",
                    pt: 10,
                    }}
                >
                    <Typography sx={{ mb: 3, fontFamily: "PdsFont", fontSize: "20px" }}>
                    Yours Sincerely,
                    </Typography>

                    <Typography sx={{ mb: 3, fontFamily: "PdsFont", fontSize: "20px" }}>
                    Signature with seal
                    </Typography>

                    {/* <Typography sx={{ mb: 3, fontFamily: "PdsFont", fontSize: "20px" }}>
                    模ま著横クトミ計遊アエ山額はぞりう
                    </Typography> */}
                    <Typography sx={{ fontFamily: 'PdsFont', lineHeight: '30px' , fontSize: '20px', mb: 3 }}>{dmData.organization.nameJp}</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '20px', mb: 3 }}>{dmData.organization.nameEng}</Typography>
                    <Typography sx={{ fontFamily: "PdsFont", fontSize: "20px" }}>
                    CEO : {dmData.organization.chairmanJp} {dmData.organization.chairmanEng}
                    </Typography>
                </Box>
            </>
        }
    </Box>
  );
};

export default DlEng;
