import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const COE11_1 = () => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    const [ memberData,setMemberData ] = useState(null)
  
    useEffect(()=>{
      if(printData && printData.data && printData.data.members){
        const data = printData.data.members[0]
        setMemberData(data)
      }
    },[printData])
    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            {
                memberData &&
                <>
                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: '28px',
                        fontFamily: 'Mincho',
                        mb: 5
                    }}>講習実施施設概要書</Typography>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '10px', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>名称</td>
                                <td colSpan={5} style={{ border: '2px solid #000', padding: '10px' , fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>KAKEHASHI Japanese Language Centre</td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '10px', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>住所</td>
                                <td colSpan={5} style={{ border: '2px solid #000', padding: '10px' , fontFamily: 'Mincho', fontSize: '23px' }}>
                                    NO.(3), 62A Street, Between 30th x 31st Streets, 62nd x 63rd Streets, Block no.(816), Pyi Gyi Myat Shin,
                                    Chan Aye Thar San Township,Mandalay, Myanmar.
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '10px', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>TEL</td>
                                <td colSpan={2} style={{ border: '2px solid #000', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>95-9-777510878</td>
                                <td style={{ border: '2px solid #000', padding: '10px', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>FAX</td>
                                <td colSpan={2} style={{ border: '2px solid #000', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>-</td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '10px', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>設立年月日</td>
                                <td colSpan={5} style={{ border: '2px solid #000', padding: '10px' , fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>2017年　7月　14日</td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '10px', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>施設規模</td>
                                <td colSpan={5} style={{ border: '2px solid #000', padding: '10px' , fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>269㎡（全教室数　　3室）</td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '10px', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', whiteSpace: 'nowrap' }}>講習実施可能人数</td>
                                <td colSpan={5} style={{ border: '2px solid #000', padding: '10px' , fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>6 人</td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '10px', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>責任者</td>
                                <td style={{ border: '2px solid #000', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>役職</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>校長</Typography>
                                </td>
                                <td colSpan={4} style={{ border: '2px solid #000',  padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>氏名</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>CHIT SU WAI</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '10px', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>指導員①</td>
                                <td style={{ border: '2px solid #000', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>役職</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>副校長</Typography>
                                </td>
                                <td colSpan={3} style={{ border: '2px solid #000', padding: '10px', width: '400px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>氏名</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>PHYU HNIN WAI</Typography>
                                </td>
                                <td style={{ border: '2px solid #000', padding: '10px', width: '200px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>経験年数</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>6年</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '10px', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>指導員②</td>
                                <td style={{ border: '2px solid #000', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>役職</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>講師</Typography>
                                </td>
                                <td colSpan={3} style={{ border: '2px solid #000', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>氏名</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>PHOO MYAT CHEL</Typography>
                                </td>
                                <td style={{ border: '2px solid #000', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>経験年数</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>2年</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '10px', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>指導員③</td>
                                <td style={{ border: '2px solid #000', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>役職</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>講師</Typography>
                                </td>
                                <td colSpan={3} style={{ border: '2px solid #000', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>氏名</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>ZAW MYO THANT</Typography>
                                </td>
                                <td style={{ border: '2px solid #000', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>経験年数</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>1年</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '10px', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>指導員④</td>
                                <td style={{ border: '2px solid #000', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>役職</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>講師</Typography>
                                </td>
                                <td colSpan={3} style={{ border: '2px solid #000', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>氏名</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>KYAW ZIN THET</Typography>
                                </td>
                                <td style={{ border: '2px solid #000', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>経験年数</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>1年</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '10px', fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center' }}>指導員⑤</td>
                                <td style={{ border: '2px solid #000', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>役職</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>講師</Typography>
                                </td>
                                <td colSpan={3} style={{ border: '2px solid #000', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>氏名</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>SOE YADANAR HTET</Typography>
                                </td>
                                <td style={{ border: '2px solid #000', padding: '10px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left' }}>経験年数</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'center', mt: 3 }}>1年</Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'right', mt: 3 }}>
                        {splitDate(memberData.coeDcDate).year} 年 　{splitDate(memberData.coeDcDate).month} 月 　{splitDate(memberData.coeDcDate).day} 日　　　作成
                    </Typography>
                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left', mt: 5 }}>講習実施施設名：KAKEHASHI Japanese Language Centre</Typography>
                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '24px', textAlign: 'left', mt: 3 }}>責任者役職・氏名：校長　CHIT SU WAI　　　　　　　　　  　　　　　　㊞</Typography>
                </>
            }
        </Box>
    )
}

export default COE11_1