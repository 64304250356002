import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  rotatedBox: {
    width: "100%",
    transform: `rotate(-90deg) translate(0px, -250px)`,
    // overflowX: 'auto',
  },
  tableCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: 'PdsFont',
    fontSize: '24px',
    border: '2px solid #000',
    padding: "15px",
  },
});

const ListOfWorker = () => {
    const [ preData,setPreData ] = useState(null)
    const pdfData = useSelector(state=> state.PrintData.data)
    const classes = useStyles();
    
    useEffect(()=>{
      if(pdfData){
        const { data } = pdfData
        setPreData(data)
      }
    },[pdfData])


  return (
    <div className={classes.screen}>
      {
        preData && preData.listOfWorkers &&
        <>
          <div style={{ height: '500px', width: '100%' }}></div>
          <Box className={classes.rotatedBox}>
            <Box textAlign="center">
              <Typography sx={{ fontSize: "26px", fontFamily: "PdsFont", mb: 5 }}>
                <span style={{ textDecoration: "underline", fontSize: "26px", fontFamily: "PdsFont" }}>
                  Clover Mandalay Co.,Ltd
                </span>
                အေဂျင်စီမှ ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်း<br /> တက်ရောက်ခွင့်ပြုရန်တင်ပြသည့် အလုပ်သမားစာရင်း
              </Typography>
            </Box>
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <tbody>
                <tr>
                  <td rowSpan={2} className={classes.tableCell}>
                    စဥ်
                  </td>
                  <td rowSpan={2} className={classes.tableCell}>
                    အေဂျင်စီအမည်
                  </td>
                  <td rowSpan={2} className={classes.tableCell}>
                    သွားရောက်မည့်နိုင်ငံ
                  </td>
                  <td rowSpan={2} className={classes.tableCell}>
                    အလုပ်သမားအမည်
                  </td>
                  <td rowSpan={2} className={classes.tableCell}>
                    နိုင်ငံကူးလက်မှတ်အမှတ်
                  </td>
                  <td colSpan={3} className={classes.tableCell}>
                    လုပ်သားဦးရေ
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>ကျား</td>
                  <td className={classes.tableCell}>မ</td>
                  <td className={classes.tableCell}>ပေါင်း</td>
                </tr>
                {preData.listOfWorkers.map((person, index) => (
                  <tr key={index}>
                    <td className={classes.tableCell}>{index + 1}</td>
                    <td className={classes.tableCell}>Clover Mandalay Co.,Ltd</td>
                    <td className={classes.tableCell}>Japan</td>
                    <td className={classes.tableCell}>{person.nameEng}</td>
                    <td className={classes.tableCell}>{person.passport}</td>
                    <td className={classes.tableCell}>{person.gender !== "女" ? "1" : "-"}</td>
                    <td className={classes.tableCell}>{person.gender === "女" ? "1" : "-"}</td>
                    <td className={classes.tableCell}>1</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          <div style={{ height: '500px', width: '100%' }}></div>
        </>
      }
    </div>
  );
};

const personList = [
  {
    agencyName: "Clover Mandalay Co.,Ltd",
    country: "Japan",
    name: "HTWE HLAING",
    passportNo: "1M-111111",
    gender: 1,
  },
  {
    agencyName: "Clover Mandalay Co.,Ltd",
    country: "Japan",
    name: "MYA NANDAR KHAING",
    passportNo: "1M-111111",
    gender: 1,
  },
];

export default ListOfWorker;
