import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import { useSelector } from "react-redux";
import { splitDate } from "../../../../utils/splitDate";
import { getBurmeseMonthName } from "../../../../utils/getBurmeseMonthName";
import { getFullMonthName } from "../../../../utils/getFullMonthName";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
        minHeight: '100vh'
    },
});

const StAndDiv = () => {
    const [ cardData,setCardData ] = useState(null)
    const pdfData = useSelector(state=> state.PrintData.data)
    const [ stAndDivData,setStAndDivData ] = useState(null)
    const classes = useStyles();

    const personList = stAndDivData ? [
        {
            stateName: 'ကချင်',
            maleQty: stAndDivData["Kachin State"].male,
            femaleQty: stAndDivData["Kachin State"].female,
        },
        {
            stateName: 'ကယား',
            maleQty: stAndDivData["Kayah State"].male,
            femaleQty: stAndDivData["Kayah State"].female,
        },
        {
            stateName: 'ကရင်',
            maleQty: stAndDivData["Kayin State"].male,
            femaleQty: stAndDivData["Kayin State"].female,
        },
        {
            stateName: 'ချင်း',
            maleQty: stAndDivData["Chin State"].male,
            femaleQty: stAndDivData["Chin State"].female,
        },
        {
            stateName: 'မွန်',
            maleQty: stAndDivData["Mon State"].male,
            femaleQty: stAndDivData["Mon State"].female,
        },
        {
            stateName: 'ရခိုင်',
            maleQty: stAndDivData["Rakhine State"].male,
            femaleQty: stAndDivData["Rakhine State"].female,
        },
        {
            stateName: 'ရှမ်း',
            maleQty: stAndDivData["Shan State"].male,
            femaleQty: stAndDivData["Shan State"].female,
        },
        {
            stateName: 'ရန်ကုန်',
            maleQty: stAndDivData["Yangon Division"].male,
            femaleQty: stAndDivData["Yangon Division"].female,
        },
        {
            stateName: 'ဧရာဝတီ',
            maleQty: stAndDivData["Ayeyarwady Division"].male,
            femaleQty: stAndDivData["Ayeyarwady Division"].female,
        },
        {
            stateName: 'ပဲခူး',
            maleQty: stAndDivData["Bago Division"].male,
            femaleQty: stAndDivData["Bago Division"].female,
        },
        {
            stateName: 'မန္တလေး',
            maleQty: stAndDivData["Mandalay Division"].male,
            femaleQty: stAndDivData["Mandalay Division"].female,
        },
        {
            stateName: 'စစ်ကိုင်း',
            maleQty: stAndDivData["Sagaing Division"].male,
            femaleQty: stAndDivData["Sagaing Division"].female,
        },
        {
            stateName: 'မကွေး',
            maleQty: stAndDivData["Magway Division"].male,
            femaleQty: stAndDivData["Magway Division"].female,
        },
        {
            stateName: 'တနင်္သာရီ',
            maleQty: stAndDivData["Tanintharyi Division"].male,
            femaleQty: stAndDivData["Tanintharyi Division"].female,
        },
        {
            stateName: 'နေပြည်တော်',
            maleQty: stAndDivData["Naypyitaw Division"].male,
            femaleQty: stAndDivData["Naypyitaw Division"].female,
        },
    ]: []
    
    
    useEffect(()=>{
        if(pdfData){
            const { data } = pdfData
            setCardData(data)
            setStAndDivData(data.numberOfWorkersDependingOnGendersAccordingtoDivisions)
        }
    },[pdfData])

    return (
        <div className={classes.screen}>
            { cardData &&
                <Box sx={{
                    textAlign: 'center',
                    p: 10
                }}>
                    <Typography sx={{
                        textAlign: 'end',
                        fontFamily: 'PdsFont',
                        fontSize: '22px',
                        lineHeight: '40px'
                    }}>ပူးတွဲ - ၁</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '22px', mb: 3 }}>
                        {convertToMyanmarNumbers(splitDate(cardData.smartCardDate).year)}ခုနှစ်
                        &nbsp;{getBurmeseMonthName(getFullMonthName(splitDate(cardData.smartCardDate).month))}လ
                        အတွင်း
                    </Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '22px', mb: 3 }}>တိုင်းဒေသကြီးနှင့် ပြည်နယ်အလိုက် ပြည်ပအလုပ်အကိုင်သမားသက်သေခံကတ်ပြား</Typography>
                    <Typography sx={{ fontFamily: 'PdsFont', fontSize: '22px', mb: 5 }}>ထုတ်ယူသည့် အလုပ်သမားများ၏ ကိုယ်ရေးအချက်အလက် အခြေပြဇယား</Typography>
                    <table border='1' style={{ borderCollapse: 'collapse', width: '100%', height: '100%', marginTop: '30px' }}>
                        <tbody>
                            <tr>
                                <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', width: '50px', fontFamily: 'PdsFont', fontSize: '22px' }}>စဥ်</td>
                                <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>တိုင်းဒေသကြီး/ပြည်နယ်</td>
                                <td colSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>ပြည်ပအလုပ်အကိုင် အလုပ်သမား သက်သေခံကတ်ပြားထုတ်ယူသူဦးရေ</td>
                                <td rowSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px', width: '200px' }}>စုစုပေါင်း</td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', width: '200px', fontFamily: 'PdsFont', fontSize: '22px', padding: '10px 0px' }}>ကျား</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', width: '200px', fontFamily: 'PdsFont', fontSize: '22px', padding: '10px 0px' }}>မ</td>
                            </tr>
                            {
                                personList.map((person, index) => (
                                    <tr key={index}>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>{convertToMyanmarNumbers(index + 1)}</td>
                                        <td style={{ textAlign: 'left', verticalAlign: 'middle', padding: '15px 10px', fontFamily: "PdsFont", fontSize: '22px' }}>{person.stateName}</td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>{person.maleQty === 0 ? 0 : person.maleQty}</td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>{person.femaleQty === 0 ? 0 : person.femaleQty}</td>
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>{(person.maleQty + person.femaleQty) === 0 ? 0 : person.maleQty + person.femaleQty}</td>
                                    </tr>
                                ))
                            }
                            <tr>
                                <td colSpan={2} style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>စုစုပေါင်း</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>{personList.reduce((total, person) => total + person.maleQty, 0)}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>{personList.reduce((total, person) => total + person.femaleQty, 0)}</td>
                                <td style={{ textAlign: 'center', verticalAlign: 'middle', padding: '15px 0px', fontFamily: "PdsFont", fontSize: '22px' }}>{personList.reduce((total, person) => total + person.maleQty + person.femaleQty, 0)}</td>
                            </tr>
                        </tbody>
                    </table>
                </Box>
            }
        </div>
    )
}

export default StAndDiv